import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import clickUrl from 'assets/sounds/click-01.wav';

const S = {
  Container: styled.div`
    display: flex;
    padding: 1rem;
    height: 100vh;
    width: 100%;
    justify-content: center;
  `,
  Metronome: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 4rem;
    gap: 1rem;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
  `,
  MButton: styled.button`
    width: 100%;
    padding: 0.4rem;
    font-size: 2rem;
    color: black;
  `,
  Meter: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
};

const audioCtx = new AudioContext();

// https://github.com/mdn/webaudio-examples/blob/main/step-sequencer/index.html#L426
function Playground () {

  const [clickSample, setClickSample] = useState<AudioBuffer>();
  let isPlaying = useRef(false);

  useEffect(() => {
    const setupSample = async (audioCtx: AudioContext) => {
      return await getFile(audioCtx, clickUrl);
    };

    const getFile = async (audioContext :AudioContext, filePath: URL) => {
      const response = await fetch(filePath);
      const arrayBuffer = await response.arrayBuffer();
      return await audioContext.decodeAudioData(arrayBuffer);
    };

    setupSample(audioCtx).then((sample) => {
      setClickSample(sample);
    }).catch(error => console.error(error));

  }, []);


  const [tempoDisplay , setTempoDisplay] = useState(60.0);
  const tempo = useRef(60.0);
  const lookahead = 25.0;
  const schedulerAheadTime = 0.1

  let nextNoteTime = 0;
  let currentNote = useRef(0);

  //https://react.dev/learn/referencing-values-with-refs
  const timerID:any = useRef(null);
  const scheduler = () => {
    while(nextNoteTime < audioCtx.currentTime + schedulerAheadTime) {
      // scheduleNote(nextNoteTime);
      // playSample(nextNoteTime);
      playOsc(nextNoteTime);
      nextNote();
    }
    timerID.current = setTimeout(scheduler, lookahead);
  }

  const nextNote = () => {
    const secondsPerBeat = 60.0 / tempo.current;

    nextNoteTime += secondsPerBeat; // Add beat length to last beat time

    // Advance the beat number, wrap to zero when reaching 4
    currentNote.current = (currentNote.current + 1) % 4;
  };

  const scheduleNote = (time: number) => {
    playSample(time);
    playOsc(time);
  };

  // https://github.com/mdn/webaudio-examples/blob/main/step-sequencer/index.html#L219
  const playOsc = (time: number) => {
    const squareOsc = new OscillatorNode(audioCtx, {
      //https://www.songstuff.com/recording/article/music-fundamental-frequencies/
      frequency: currentNote.current === 0 ? 830.61 : 587.33, //G#5, D5
      type: 'square'
    });

    //https://github.com/mohayonao/adsr-envelope
    const squareEnv = new GainNode(audioCtx);
    const env = {
      attack: 0.001, // ms
      decay: 0.136, //ms
      sustain: 0,  // percent scale from 0 to 1
      release: 0.010 // ms
    };

    squareEnv.gain.cancelScheduledValues(time);
    squareEnv.gain.setValueAtTime(0, time);
    squareEnv.gain.linearRampToValueAtTime(1, time + env.attack);
    squareEnv.gain.linearRampToValueAtTime(env.sustain, time + env.attack + env.decay); // decay
    squareEnv.gain.linearRampToValueAtTime(0, time + env.attack + env.decay + env.release); //release

    squareOsc.connect(squareEnv).connect(audioCtx.destination);
    squareOsc.start(time);
    squareOsc.stop(time + env.decay);
  };

  const playSample = (time: number) => {
    const playbackRate = 1;
    const sampleSource = new AudioBufferSourceNode(audioCtx, {
      buffer: clickSample,
      playbackRate: playbackRate,
    });
    sampleSource.connect(audioCtx.destination);
    sampleSource.start(time);
    return sampleSource;
  }

  const play = (se: SyntheticEvent) => {
    se.preventDefault();

    isPlaying.current = !isPlaying.current;

    if (isPlaying.current) {
      if (audioCtx.state === "suspended") {
        audioCtx.resume().then(() => console.log('audio ctx resumed'));
      }

      currentNote.current = 0;
      nextNoteTime = audioCtx.currentTime;
      scheduler();
    } else {
      clearTimeout(timerID.current);
    }
  };

  const plus = (se: SyntheticEvent) => {
    se.preventDefault();
    if (tempoDisplay + 4 <= 252) setTempoDisplay(tempoDisplay + 4);
    if (tempo.current + 4 <= 252) tempo.current = tempo.current + 4;
  }

  const subtract = (se: SyntheticEvent) => {
    se.preventDefault();
    if (tempoDisplay - 4 >= 40) setTempoDisplay(tempoDisplay - 4);
    if (tempo.current - 4 >= 40) tempo.current = tempo.current - 4;
  }

  return (
    <S.Container>
      <S.Metronome>
        <S.Meter> 4/4 </S.Meter>
        <S.MButton onClick={plus}>+4</S.MButton>
        {tempoDisplay}
        <S.MButton onClick={subtract}>-4</S.MButton>
        <S.MButton onClick={play}>▶️</S.MButton>
      </S.Metronome>
    </S.Container>
);
}

export default Playground;
